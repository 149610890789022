import { AncillaryProductEnrollment } from '@frontline/common/dist/models/Application/LoanDetails/ancillary-product-enrollment/ancillary-product-enrollment.interface';
import { HomeAndAutoAncillaryProductRequest } from '@frontline/web-common';

export const isHomeAndAutoAncillaryProductEnrollmentChanged = (
  request: HomeAndAutoAncillaryProductRequest | null,
  applicationAncillaryProductEnrollment?: AncillaryProductEnrollment,
): boolean => {
  if (!applicationAncillaryProductEnrollment) {
    return true;
  }
  return Boolean(
    request?.type !== applicationAncillaryProductEnrollment.homeAndAutoType ||
      request?.years !== applicationAncillaryProductEnrollment.homeAndAutoYears,
  );
};
