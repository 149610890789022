import { updateWorkflowStepsStatus } from '@frontline/common';
import { Loader, muiTheme, withShowIf } from '@frontline/ui-library';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import startCase from 'lodash/startCase';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from '../../../../../../modules/layout/components';
import ApplicationLayout from '../../../../common/ApplicationLayout/ApplicationLayout';
import * as applicantApplicationOptionsStore from '../../../../modules/applicant-application-options/store/applicant-application-options.store';
import * as applicationStore from '../../../../store/application.store';
import { ReviewPageMobileNavigation } from '../../common';
import * as store from '../../store/post-submission.store';
import { PostSubmissionReviewHeader } from './components/post-submission-review-header';
import { PostSubmissionReviewSidebar } from './components/post-submissoin-review-sidebar';
import { PaymentCalculatorEstimateContext } from './contexts/PaymentCalculatorEstimateContext';
import { usePaymentCalculatorEstimates } from './hooks/usePaymentCalculatorEstimates';
import { PostSubmissionReviewRoutes } from './post-submission-review.routes';
import { PostSubmissionReviewFeatureFunctions } from './types/PostSubmissionReviewFeature.functions';
import { getEnabledWorkflowSteps } from './types/workflow.functions';

const PostSubmissionReview: FunctionComponent = () => {
  const applicationProgramType = useSelector(applicationStore.getProgramType);
  const allSteps = useSelector(store.getWorkflow);
  const applicationId = useSelector(applicationStore.getApplicationId);
  const isMediaExtraSmall = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const loanDetails = useSelector(applicationStore.getLoanDetails);
  const estimatedPayment = useSelector(store.getEstimatedPayment);

  const workflowStep = useSelector(store.getActiveWorkflowStep);
  const applicantApplicationOption = useSelector(
    applicantApplicationOptionsStore.getApplicantApplicationOption,
  );

  const isLoading = useSelector(applicantApplicationOptionsStore.isLoading);

  const application = useSelector(applicationStore.getApplication);
  const stepsForPostSubmissionReviewSideBar = useSelector(
    store.getPostSubmissionStepsForStepper,
  );

  const {
    fetchEstimatedPayment,
    paymentCalculatorRequestValues,
  } = usePaymentCalculatorEstimates(loanDetails, application);

  updateWorkflowStepsStatus(stepsForPostSubmissionReviewSideBar, application);

  return (
    <PaymentCalculatorEstimateContext.Provider
      value={{
        fetchEstimatedPayment,
        paymentCalculatorRequestValues,
        estimatedPayment,
      }}>
      <Loader show={isLoading} />
      <PostSubmissionReviewContent showIf={!isLoading}>
        <ApplicationLayout maxWidth="1200px">
          <PostSubmissionReviewHeader
            applicationId={applicationId}
            showBackToOverviewButton={true}
          />
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} md={8}>
              {applicationId && (
                <PostSubmissionReviewRoutes
                  applicationId={applicationId}
                  steps={allSteps}
                />
              )}
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <PostSubmissionReviewSidebar
                showIf={!isMediaExtraSmall && !isLoading}
                applicationProgramType={applicationProgramType}
                steps={getEnabledWorkflowSteps(
                  stepsForPostSubmissionReviewSideBar,
                  applicantApplicationOption,
                )}
                applicationStatus={useSelector(store.getApplicationStatus)}
                estimatedPayment={estimatedPayment}
                estimatedPaymentIsCalculating={useSelector(store.isCalculating)}
                estimatedFrequency={useSelector(store.getEstimatedFrequency)}
                applicantName={startCase(
                  useSelector(applicationStore.getApplicantFirstName),
                )}
                coApplicantName={startCase(
                  useSelector(applicationStore.getCoApplicantFirstName),
                )}
                uploadDocumentsStep={useSelector(store.getUploadDocumentsStep)}
                hideFundingDetails={PostSubmissionReviewFeatureFunctions.hideFundingDetails(
                  applicantApplicationOption,
                  workflowStep,
                )}
                applicantOptions={applicantApplicationOption}
              />
            </Grid>
          </Grid>
        </ApplicationLayout>
      </PostSubmissionReviewContent>
    </PaymentCalculatorEstimateContext.Provider>
  );
};

const PostSubmissionReviewContent = withShowIf(
  (props: PropsWithChildren<any>) => <>{props.children}</>,
);

export const PostSubmissionReviewFeature = MainLayout(PostSubmissionReview, {
  mobileToolBar: ReviewPageMobileNavigation,
  mobileExtraSmallNavBarHeight: '112px',
});
